import { Button, HStack, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import {
  GA_CATEGORY_WORKSPACE_PANEL,
  sectionDetailedTrack,
} from '../../../../repository/repo.utils'
import ActivityJoinModal from './activityJoinModal'

const JoinAsParticipant = ({ activity, reloadParticipant }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOnClose = () => {
    reloadParticipant()
    onClose()
  }

  return (
    <>
      {isOpen ? (
        <ActivityJoinModal
          isOpen={isOpen}
          onClose={(_, d) => (d ? handleOnClose() : onClose())}
          activity={activity}
        />
      ) : null}
      <HStack
        w="100%"
        px={2}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          borderRadius="sm"
          boxShadow="md"
          size="sm"
          colorScheme={localStorage.getItem('color')}
          bg={localStorage.getItem('color')}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_WORKSPACE_PANEL,
              action: 'Team Collaboration',
              label: 'Join Participant',
            })
            onOpen()
          }}
        >
          Join {activity.activity_title}
        </Button>
      </HStack>
    </>
  )
}

export default JoinAsParticipant

import {
	Button,
	FormControl,
	HStack,
	Icon,
	IconButton,
	Text,
	Modal,
	ModalBody,
	ModalOverlay,
	ModalCloseButton,
	ModalHeader,
	ModalContent,
	useDisclosure,
	Tooltip,
	Box,
	Flex,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ImAttachment } from 'react-icons/im'
import { IoIosSend, IoMdClose } from 'react-icons/io'
import { MdFilterList, MdPersonAdd, MdPersonOutline } from 'react-icons/md'
import { BiMailSend } from 'react-icons/bi'
import { Mention, MentionsInput } from 'react-mentions'

import CustomMenu from '../../../../../components/menu'
import { useAuthContext } from '../../../../../context/auth.context'
import { useSharedContext } from '../../../../../context/shared.context'
import {
	useAddTimelineUpdate,
	useInviteParticipant,
	useTimilineFilterOptions,
} from '../../../../../hooks/activity.hooks'
import {
	sanitizeContent,
	validateEmailUsernamePassword,
} from '../../../../../utils/common.util'
import {
	formatNoteUpdateDate,
	getCurrentDateUTC,
} from '../../../../../utils/date.utils'
import { sharedConst } from '../../../../../utils/action.constant'
import { timelineOptions } from '../../../../../components/timeline/util'
import { useLoadUsersOnSearch } from '../../../../../hooks/asset.hooks'
import {
	useUpdateMentionUnreads,
	useMentionsSendMail,
} from '../../../../../hooks/shared.hooks'
import OutlookPasswordSetup from '../outllookPassword'
import './styles.scss'
import { AlertBox } from './../../../../../components/AlertBox/index'
import { toConvertSentenceCase } from '../../../../../utils/form.util'
import { useLanguageContext } from '../../../../../context/locale.context'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// 	GA_CATEGORY_WORKSPACE_PANEL,
// 	sectionDetailedTrack,
// } from './../../../../repository/repo.utils'

const TimelineBottomBar = ({
	activity,
	openAttachment,
	onOpenParticipantView,
	isReplyOn,
	timelineReplyData,
	replyData,
	onOpenSendEmail,
	fromExternal = false,
	isMobileView,
}) => {
	const { mutate } = useAddTimelineUpdate()
	const {
		state: { timelineFilter },
		dispatch,
	} = useSharedContext()

	const { mutate: mutateLoadAssets } = useLoadUsersOnSearch()
	const { mutate: mutateUnreadUpdate } = useUpdateMentionUnreads()
	const { mutate: mutateSendMentionEmail } = useMentionsSendMail()
	const { mutate: inviteMutate } = useInviteParticipant()
	const { mutate: mutateTimelineOptions } = useTimilineFilterOptions()

	const [searchText, setSearchText] = useState('')
	const [users, setUsers] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [timelineFilterList, setTimelineFilterList] = useState([])
	const [defaultFilterOption, setDefaultFilter] = useState({})
	const [alertMessage, setAlertMessage] = useState('')
	const submitBtnRef = useRef(null)
	const formRef = useRef(null)

	const {
		isOpen: isConfirm,
		onOpen,
		onToggle: onToggleConfirm,
	} = useDisclosure()

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		operating_asset_first_name,
		organization_flag_enable_attachment_access_control,
		default_timeline_filter_id,
		flag_disable_timeline_filter,
	} = authData
	const {
		activity_id,
		activity_type_id,
		activity_type_category_id,
		activity_title,
		asset_email_id,
	} = activity || {}
	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm()

	useEffect(() => {
		setSearchText('')
		timelineOptions123()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activity])

	const {
		timeline_transaction_id,
		timeline_stream_type_id,
		data_form_transaction_id,
		data_entity_inline,
		operating_asset_first_name: msg_asset_first_name,
		log_asset_id,
		log_asset_first_name,
		log_asset_last_name,
		title,
	} = !!replyData ? replyData : {}

	const onComment = () => {
		if (!searchText || !searchText.trim()) {
			alert('Comment should not be empty')
			return
		}

		// eventLabelTrack({
		// 	categoryId: 0,
		// 	actionId: 4,
		// 	label: `Send Message`,
		// })

		// dataLayerTagManager('button_click', {
		// 	viewName: 'File Timeline',
		// 	buttonName: 'Send',
		// })

		let comment = toConvertSentenceCase(searchText)
		setSearchText('')
		let { is_password_set, organization_flag_email_integration_enabled } =
			authData

		let msgReplyParams = {
			timeline_transaction_id: timeline_transaction_id,
			timeline_transaction_stream_type_id: timeline_stream_type_id,
			form_transaction_id: data_form_transaction_id,
			content: title, //inlineData.mail_body,
			log_asset_id,
			log_asset_first_name: msg_asset_first_name,
			log_asset_last_name,
		}
		let payload = {
			content: sanitizeContent(comment),
			subject: 'Note - ' + formatNoteUpdateDate(),
			mail_body: sanitizeContent(comment),
			attachments: [],
			activity_reference: [{ activity_title: '', activity_id: '' }],
			asset_reference: [],
			form_approval_field_reference: [],
			is_reply: !!isReplyOn ? 1 : 0,
			replying_to: !!isReplyOn ? msgReplyParams : null,
		}
		mutate(
			{
				operating_asset_first_name,
				activity_id,
				activity_type_category_id,
				activity_type_id,
				// data_entity_inline: JSON.stringify(payload),
				activity_timeline_collection: JSON.stringify(payload),
			},
			{
				onSuccess: async data => {
					setSearchText('')
					dispatch({
						type: sharedConst.REFRESH_TIMELINE,
					})
					timelineReplyData(null)
					console.log('Timeline comment added', data)
				},
			}
		)
		mutateUnreadUpdate(
			{
				...prepareMentionUnreadParams(),
			},
			{
				onSuccess: async data => {
					console.log('data', data)
				},
				onError: async err => {
					console.log('err', err)
				},
			}
		)
		if (!!selectedUsers && selectedUsers?.length) {
			inviteMutate(
				{
					activity_id,
					activity_type_category_id: activity_type_category_id,
					activity_type_id,
					participants: selectedUsers.map(u => ({
						...u,
						asset_category_id: 1,
						access_role_id: 1,
					})),
				},
				{
					onSuccess: async data => {
						setSelectedUsers([])
					},
				}
			)
		}

		// TODO: 1 : outlook
		// TODO: 2 : standard : no need to check password

		// If no user selected, sendMentionMail is not being called
		if (!!selectedUsers.length && validateSendMail()) {
			mutateSendMentionEmail(
				{
					...sendMentionsMailParams(),
				},
				{
					onSuccess: async data => {
						setSelectedUsers([])
						console.log('daata', data)
					},
					onError: async err => {
						setSelectedUsers([])
						console.log('err', err)
					},
				}
			)
		} else {
			setSelectedUsers([])
		}
	}

	const validateSendMail = () => {
		if (authData.organization_flag_email_integration_enabled === 1) {
			const { valid, msg } = validateEmailUsernamePassword(authData)
			if (valid) return true
			else {
				setAlertMessage(msg)
				onOpen()
				return false
			}
		} else {
			return true
		}
	}

	const prepareMentionUnreadParams = () => {
		return {
			assets_referenced: selectedUsers
				? selectedUsers.map(user => user.asset_id)
				: [],
			activity_id: activity_id,
		}
	}

	const sendMentionsMailParams = () => {
		return {
			workflow_title: activity_title,
			workflow_update: sanitizeContent(searchText),
			datetime_log: getCurrentDateUTC(),
			mentioned_assets: selectedUsers
				? selectedUsers.map(user => user.asset_id)
				: [],
			mentioned_by_asset_name: operating_asset_first_name,
			workflow_activity_id: activity_id,
			activity_type_id: activity_type_id,
			activity_type_category_id: activity_type_category_id,
			email_sender: authData.asset_email_id,
			is_version_v1: 1,
		}
	}

	const timelineOptions123 = () => {
		mutateTimelineOptions(
			{
				params: {
					flag: 1,
					filter_id: 1,
				},
			},
			{
				onSuccess: async data => {
					let newData = []

					newData = data.length
						? data.map(item => {
								let obj = {}
								obj.filter_id = item.filter_id
								obj.value = item.filter_name
								obj.label = item.filter_description
								return obj
						  })
						: []

					setTimelineFilterList(newData)
					let defaultFilter = newData.length
						? newData.filter(item => {
								if (item.filter_id === default_timeline_filter_id) {
									return item
								}
								return ''
						  })
						: []
					if (defaultFilter.length && flag_disable_timeline_filter !== 0) {
						setDefaultFilter(defaultFilter[0])
						dispatch({
							type: sharedConst.TIME_LINE_FILTER,
							filterValue: defaultFilter[0],
						})
					}
				},
				onError: async err => {
					console.log('err', err)
				},
			}
		)
	}

	const handleText = e => {
		setSearchText(e.target.value)
		if (e.target.value === '') {
			setSelectedUsers([])
		}
	}

	const loadUsers = (query, callback) => {
		mutateLoadAssets(
			{
				params: {
					limit_value: 100,
					start_from: 0,
					asset_type_category_id: 3,
					search_string: query.toLowerCase(),
					is_createdesk: 1,
				},
			},
			{
				onSuccess: async data => {
					setUsers(data)
					let displayList = data.map(emp => {
						return {
							display: `${emp.operating_asset_first_name} - ${
								authData.organization_flag_mention_setting === 1
									? emp.asset_type_name
									: emp.account_name
							}`,
							id: emp.asset_id,
						}
					})
					callback(displayList)
				},
				onError: async err => {
					console.log('err', err)
				},
			}
		)
	}

	const checkUserId = id => {
		const user = !!users && users.find(emp => emp.asset_id === id)
		return user
	}

	const handleComments = id => {
		if (selectedUsers.length > 0) {
			if (!selectedUsers.some(item => item.asset_id === id)) {
				let getUser = checkUserId(id)
				if (!!getUser) {
					setSelectedUsers([...selectedUsers, getUser])
				}
			}
		} else {
			let getUser = checkUserId(id)
			if (!!getUser) {
				setSelectedUsers([getUser])
			}
		}
	}

	useEffect(() => {
		// Alternate approach for keyeventhandler package
		const handleKeyDown = event => {
			if (
				(event.altKey && event.key === 'Enter') ||
				(event.altKey && event.key === 'Return')
			) {
				if (submitBtnRef.current) {
					submitBtnRef.current.click()
				}
			}
		}

		const formElement = formRef.current
		if (formElement) {
			formElement.addEventListener('keydown', handleKeyDown)
		}

		return () => {
			if (formElement) {
				formElement.removeEventListener('keydown', handleKeyDown)
			}
		}
	}, [])

	return (
		<HStack w='100%' px={2} spacing={2} alignItems='center'>
			<AlertBox
				isOpen={isConfirm}
				onClose={onToggleConfirm}
				Message={alertMessage}
			/>
			<form
				onSubmit={handleSubmit(onComment)}
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'space-around',
					alignItems: 'center',
				}}
				ref={formRef}
			>
				<FormControl id='message' flex={1} mr={2} className='message-box'>
					{!!isReplyOn && (
						<Box className='reply-box'>
							<Box className='reply-content'>
								<IoMdClose
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_WORKSPACE_PANEL,
										// 	action: 'Timeline Bottom Panel',
										// 	label: 'Close',
										// })
										timelineReplyData(null)
									}}
								/>
								<Text as='small'>
									<Text fontWeight='bold'>{msg_asset_first_name}</Text>
								</Text>
								<Text>{title}</Text>
							</Box>
						</Box>
					)}
					<MentionsInput
						onChange={handleText}
						value={searchText}
						// onKeyUp={handleKeyDown}
						allowSpaceInQuery={true}
						className='mentionWrapper'
						placeholder={locale['Enter message here']}
					>
						<Mention
							trigger='@'
							data={loadUsers}
							onAdd={handleComments}
							renderSuggestion={(id, display, search, highlightedDisplay) => {
								return (
									<Text as='span' flex={1} fontSize='sm' color='black'>
										{search}
									</Text>
								)
							}}
							displayTransform={(id, display) => {
								return `@${display}`
							}}
							appendSpaceOnAdd={true}
						/>
					</MentionsInput>
					{/* <Controller
              render={() => {
                return (

                );
              }}
              name="comment"
              control={control}
              defaultValue=""
              rules={{
                required: {value: true,message: 'Comment is required!'},
              }}
            /> */}
				</FormControl>
				<Button
					type='submit'
					ref={submitBtnRef}
					variant='outline'
					leftIcon={<Icon as={IoIosSend} w={6} h={6} mr={1} />}
					_focus={{
						border: 'none',
					}}
					size='md'
					boxShadow='md'
				>
					{!isMobileView ? locale['Send'] : 'Send'}
				</Button>
			</form>
			{!fromExternal && !isMobileView && (
				<Tooltip hasArrow label={locale['Email']}>
					<IconButton
						bg='white'
						borderRadius='md'
						boxShadow='md'
						aria-label='Add participant'
						size='md'
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						icon={<Icon as={BiMailSend} w={5} h={5} />}
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_WORKSPACE_PANEL,
							// 	action: 'Timeline Bottom Panel',
							// 	label: 'Open Email Modal',
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'File Timeline',
							// 	buttonName: 'Open Email',
							// })
							onOpenSendEmail()
						}}
					/>
				</Tooltip>
			)}

			{!isMobileView ? (
				<Tooltip hasArrow label={locale['Timeline Filters']}>
					<span>
						<CustomMenu
							optionList={timelineFilterList}
							selected={
								flag_disable_timeline_filter === 0
									? timelineFilter
									: defaultFilterOption
							}
							onSelect={item => {
								// dataLayerTagManager('button_click', {
								// 	viewName: 'File Timeline',
								// 	buttonName: 'Filter',
								// })
								dispatch({
									type: sharedConst.TIME_LINE_FILTER,
									filterValue: item,
								})
							}}
							isDisabled={flag_disable_timeline_filter === 0 ? false : true}
							icon={MdFilterList}
						/>
					</span>
				</Tooltip>
			) : null}

			{!isMobileView ? (
				<Tooltip hasArrow label={locale['File Upload']}>
					<IconButton
						bg='white'
						borderRadius='md'
						boxShadow='md'
						aria-label='Add participant'
						size='md'
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						icon={<Icon as={ImAttachment} w={5} h={5} />}
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_WORKSPACE_PANEL,
							// 	action: 'Timeline Bottom Panel',
							// 	label: 'Open Attachment',
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'File Timeline',
							// 	buttonName: 'Attachments',
							// })
							openAttachment('')
						}}
					/>
				</Tooltip>
			) : null}

			{organization_flag_enable_attachment_access_control > 0 &&
				!fromExternal &&
				!isMobileView && (
					<Tooltip
						hasArrow
						label={locale['Access controlled attachments']}
						bg='red.600'
					>
						<Button
							bg='white'
							borderRadius='md'
							boxShadow='md'
							aria-label='Add participant'
							size='md'
							_focus={{
								bg: 'secondary',
							}}
							_hover={{
								bg: 'secondary',
							}}
							padding='0.5'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_WORKSPACE_PANEL,
								// 	action: 'Timeline Bottom Panel',
								// 	label: 'Open Attachment',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'File Timeline',
								// 	buttonName: 'Controlled Attachments',
								// })
								openAttachment('enableAccess')
							}}
						>
							<Flex>
								<Icon as={ImAttachment} w={5} h={5} />
								<MdPersonOutline />
							</Flex>
						</Button>
						{/* <IconButton
            bg="white"
            borderRadius="md"
            boxShadow="md"
            aria-label="Add participant"
            size="md"
            _focus={{
              bg: 'secondary',
            }}
            _hover={{
              bg: 'secondary',
            }}

            icon={ <Icon as={MdFolderShared} w={7} h={6} />}
            onClick={() => {
              openAttachment('enableAccess');
            }}
          /> */}
					</Tooltip>
				)}
			{!fromExternal && !isMobileView && (
				<Tooltip hasArrow label={locale['Add participant']}>
					<IconButton
						bg='white'
						borderRadius='md'
						boxShadow='md'
						aria-label='Add participant'
						size='md'
						_focus={{
							bg: 'secondary',
						}}
						_hover={{
							bg: 'secondary',
						}}
						icon={<Icon as={MdPersonAdd} w={5} h={5} />}
						onClick={() => {
							// eventLabelTrack({
							// 	categoryId: 0,
							// 	actionId: 6,
							// 	label: 'Open Participant Modal For An Activity',
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'File Timeline',
							// 	buttonName: 'Add Participant',
							// })
							onOpenParticipantView()
						}}
					/>
				</Tooltip>
			)}
		</HStack>
	)
}

export default TimelineBottomBar

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  FormControl,
  FormLabel,
  useDisclosure,
  ModalHeader,
  Switch,
  FormErrorMessage,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FiUploadCloud } from 'react-icons/fi'
import { MdCheck, MdClose } from 'react-icons/md'
import { useAuthContext } from '../../../../context/auth.context'
import { useSharedContext } from '../../../../context/shared.context'
import {
  useAddTimelineUpdate,
  useLoadActivityParticipant,
} from '../../../../hooks/activity.hooks'
import { sharedConst } from '../../../../utils/action.constant'
import { pushFileToS3 } from '../../../../utils/aws.util'
import { useDropzone } from 'react-dropzone'
import Select from '../../../../components/chakra-react-select'
import {
  eventLabelTrack,
  GA_CATEGORY_WORKSPACE_PANEL,
  sectionDetailedTrack,
} from './../../../repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'

// TODO: Implement the congnito based document upload

const TimelineAttachment = ({ activity, isOpen, onClose, fileAccessTyp }) => {
  const inputRef = useRef()
  const [isError, setisError] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [fileList, setfileList] = useState([])
  const [data, setdata] = useState([])
  const [selectedParticipant, setSelectedParticipant] = useState([])
  const [disableUpload, setdisableUpload] = useState(false)
  const [completedList, setcompletedList] = useState([])
  const [uploadingFile, setuploadingFile] = useState(null)
  const [participentError, setparticipentError] = useState('')
  const [isAccess, setIsAccess] = useState(false)
  const { mutate } = useAddTimelineUpdate()
  const { mutate: participantList } = useLoadActivityParticipant()
  const { dispatch } = useSharedContext()
  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()
  const {
    asset_id,
    operating_asset_first_name,
    organization_flag_enable_attachment_access_control,
  } = authData
  const { activity_id, activity_type_id, activity_type_category_id } =
    activity || {}

  useEffect(() => {
    loadParticipants()
  }, [])

  const loadParticipants = useCallback(() => {
    participantList(
      { activity_id },
      {
        onSuccess: async data => {
          let finalData = []
          if (data.list && data.list.length > 0) {
            finalData = data.list.filter(val => val.asset_id !== asset_id)
          }
          setdata(finalData || [])
        },
      }
    )
  })

  const onHandleFileChange = (selectedfiles, rejectedFiles) => {
    setisError(false)
    setdisableUpload(false)
    if (
      fileList.length + selectedfiles.length > 5 ||
      (rejectedFiles && rejectedFiles.length > 5)
    ) {
      return setdisableUpload(true)
    }
    const files = [...selectedfiles].filter(file => {
      if (file.name.split('.').length > 2) {
        return true
      }
      if (file.name.split('.').length < 2) {
        return false
      }
      return true
    })

    if (files.length === 0) {
      setisError(true)
    } else if (
      selectedfiles[0].name.split('.').pop() === 'exe' ||
      selectedfiles[0].name.split('.').pop() === 'php' ||
      selectedfiles[0].name.split('.').pop() === 'sh' ||
      selectedfiles[0].name.split('.').pop() === 'py'
    ) {
      setisError(true)
    } else {
      setfileList([...fileList, ...files])
    }
  }

  const removeFile = file => {
    let files = fileList.filter(
      f => f.name.toLowerCase() !== file.name.toLowerCase()
    )
    setfileList(files)
  }

  const fileSizeFormatted = size => {
    if (!size || size < 1000) {
      return `${size} Bytes`
    } else if (size < 1000000) {
      return `${Math.floor(size / 1000)}KB`
    } else {
      return `${Math.floor(size / 1000000)}MB`
    }
  }

  const onComment = ({ url }) => {
    let payload = {
      content: [],
      subject: url,
      mail_body: url,
      attachments: [url],
      activity_reference: [],
      asset_reference: [],
      form_approval_field_reference: [],
      is_reply: 0,
      flag_attachment_access_restricted: !!fileAccessTyp
        ? organization_flag_enable_attachment_access_control
        : 0,
      doc_access_participants: [
        ...selectedParticipant.map(p => ({
          asset_id: p.value,
          asset_name: p.label,
        })),
        organization_flag_enable_attachment_access_control === 1 && {
          asset_id: asset_id,
          asset_name: operating_asset_first_name,
        },
      ],
    }
    mutate({
      operating_asset_first_name,
      activity_id,
      activity_type_category_id,
      activity_type_id,
      // data_entity_inline: JSON.stringify(payload),
      activity_timeline_collection: JSON.stringify(payload),
    })
  }

  const onUpload = async () => {
    setparticipentError('')
    if (!!fileAccessTyp && selectedParticipant.length === 0) {
      setparticipentError('required')
      return
    }

    eventLabelTrack({
      categoryId: 0,
      actionId: 5,
      label: `Uploading Files in Timeline`,
    })

    setisLoading(true)
    const urlPromises = fileList.map(async file => {
      setuploadingFile(file)
      const blob = file.slice(0, file.size, file.type)
      const newFile = new File([blob], file.name.replace(/ /gi, '-'), {
        type: blob.type,
      })
      let result = await pushFileToS3(newFile)
      setuploadingFile(null)
      setcompletedList([...completedList, file.name])
      console.log(result)
      return result
    })
    const urls = await Promise.all(urlPromises)
    const commentPs = urls.map(async item => await onComment(item))
    await Promise.all(commentPs)
    setisLoading(false)
    setTimeout(() => {
      dispatch({
        type: sharedConst.REFRESH_TIMELINE,
      })
      onClose()
    }, 500)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onHandleFileChange,
    maxFiles: 5,
  })
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="md" bg="white" minW="70vw" m="auto" h="600px">
        <ModalCloseButton />
        <ModalBody p={0} w="100%">
          <HStack h="full">
            <Box h="full" w="40%" overflow="auto">
              <VStack h="full" justifyContent="center" alignItems="center">
                <Heading
                  textTransform="uppercase"
                  size="lg"
                  color="brand.800"
                  fontWeight="400"
                  mb={3}
                >
                  {locale['File Upload']}
                </Heading>
                <Flex
                  w="80%"
                  h="60%"
                  border="2px"
                  borderColor="brand.800"
                  borderStyle="dashed"
                  borderRadius="10px"
                  py={2}
                  cursor="pointer"
                  {...getRootProps()}
                >
                  <VStack
                    justifyContent="center"
                    w="100%"
                    alignItems="center"
                    spacing={3}
                  >
                    <Icon
                      as={FiUploadCloud}
                      w={20}
                      h={20}
                      color="brand.800"
                      strokeWidth="1px"
                    />
                    <Text color="brand.800" fontSize="18px">
                      {locale['Drag and Drop Files']}
                    </Text>
                    <Text color="brand.800" fontSize="18px" textAlign="center">
                      {locale['OR']}
                    </Text>
                    <input
                      type="file"
                      // accept={acceptedFileTypes}
                      name="file"
                      ref={inputRef}
                      style={{ display: 'none' }}
                      // disabled={disableUpload}
                      {...getInputProps()}
                    ></input>
                    <Button
                      borderRadius="md"
                      boxShadow="md"
                      size="md"
                      variant="solid"
                      colorScheme={localStorage.getItem('color')}
                      bg={localStorage.getItem('color')}
                      fontSize="14px"
                      onClick={() => {
                        sectionDetailedTrack({
                          category: GA_CATEGORY_WORKSPACE_PANEL,
                          action: 'Timeline Attachment',
                          label: 'Browse Files',
                        })
                        !!inputRef.current && inputRef.current.click()
                      }}
                    >
                      {locale['BROWSE FILES']}
                    </Button>
                    <Text color="brand.800" fontSize="10px" textAlign="center">
                      {locale['Supports']} :
                      <Text
                        display="block"
                        as="span"
                        color="brand.800"
                        fontSize="10px"
                        textAlign="center"
                        textTransform="capitalize"
                      >
                        JPG, PDF, PNG, XLX, XLSX, XLSB, Doc, Docx, Docs, ppt,
                      </Text>
                    </Text>
                    {isError && (
                      <Text
                        color="red"
                        fontSize="10px"
                        textAlign="center"
                        px={5}
                      >
                        {locale['File format not supported']} <br />{' '}
                        {locale['or']} <br />{' '}
                        {locale['File name should not contain multiple (dots)']}
                      </Text>
                    )}
                    {disableUpload ? (
                      <Text
                        color="red"
                        fontSize="10px"
                        textAlign="center"
                        px={5}
                      >
                        {locale['Max 5 file can be uploaded at time']}
                      </Text>
                    ) : null}
                  </VStack>
                </Flex>

                {/* {organization_flag_enable_attachment_access_control > 0 &&
                  <>
                    <Flex
                      p={2}
                      borderRadius="sm"
                      borderWidth={'sm'}
                      // className={`field-item-edit-container`}
                      w="full"
                      justifyContent={'space-evenly'}
                      d={'flex'}
                      paddingLeft={'25px'}
                      paddingTop={'15px'}
                      flexDirection={'row'}
                    >
                      <Text color="brand.800" fontSize="18px">
                        Do you want to enable access control to the attachments?
                      </Text>
                      <Switch
                        defaultIsChecked={isAccess}
                        colorScheme={localStorage.getItem('color')}
                        color="brand.800"
                        onChange={e => setIsAccess(e.target.checked)}
                        size={'md'}
                      />
                    </Flex> */}

                <Flex w="80%" cursor="pointer">
                  <VStack
                    justifyContent="center"
                    w="100%"
                    alignItems="center"
                    spacing={3}
                  >
                    {!!fileAccessTyp && (
                      <FormControl
                        className={`field-item-edit-form`}
                        boxShadow="md"
                        borderRadius="md"
                        id="field-edit"
                        p={2}
                        mb={2}
                        isInvalid={!!participentError}
                      >
                        <FormLabel
                          className={`field-item-form-label`}
                          fontWeight="400"
                          fontSize="sm"
                          my={1}
                          color="brand.800"
                        >
                          {locale['Select participants who']}{' '}
                          {organization_flag_enable_attachment_access_control ===
                          1
                            ? locale['can']
                            : locale["can't"]}{' '}
                          {locale['access attachments']}{' '}
                        </FormLabel>
                        <Select
                          isMulti={true}
                          options={
                            data.length > 0
                              ? (data || [])?.map(d => ({
                                  label: d.operating_asset_first_name,
                                  value: d.asset_id,
                                }))
                              : []
                          }
                          onChange={e => setSelectedParticipant(e)}
                          placeholder={`Select participant`}
                          borderRadius="md"
                          value={selectedParticipant}
                          size="xs"
                          menuPlacement={'top'}
                        />
                        {!!participentError ? (
                          <FormErrorMessage>
                            {locale['Please select participants']}
                          </FormErrorMessage>
                        ) : null}
                      </FormControl>
                    )}
                  </VStack>
                </Flex>
                {/* </>} */}
              </VStack>
            </Box>
            <VStack
              h="full"
              borderRightRadius="md"
              flex={1}
              bg="secondary"
              display="flex"
            >
              <VStack w="80%" mx="auto" flex={1} mt={10} alignItems="center">
                <List w="full" spacing={3} h="496px">
                  {(fileList || []).map((file, index) => {
                    const { name, size } = file
                    return (
                      <Box key={index}>
                        <ListItem>
                          <Flex spacing={2}>
                            <Center position="relative" alignSelf="center">
                              <Box
                                boxShadow="md"
                                bg="white"
                                borderRadius="full"
                                p={3}
                              >
                                <Text fontSize="12px" textTransform="uppercase">
                                  {name.split('.').pop()}
                                </Text>
                              </Box>
                            </Center>
                            <Box ml={2} flex={1}>
                              <Text
                                color="brand.800"
                                fontSize="14px"
                                wordBreak="break-all"
                              >
                                {name}
                              </Text>
                              <Text color="brand.800" fontSize="10px">
                                {fileSizeFormatted(size)}
                              </Text>
                            </Box>
                            {!!uploadingFile &&
                            uploadingFile.name === file.name ? (
                              <Spinner color={localStorage.getItem('color')} />
                            ) : (
                              <IconButton
                                bg="white"
                                borderRadius="md"
                                boxShadow="md"
                                aria-label="More"
                                size="sm"
                                _focus={{
                                  bg: 'secondary',
                                }}
                                _hover={{
                                  bg: 'secondary',
                                }}
                                icon={
                                  <Icon
                                    as={
                                      completedList.includes(name)
                                        ? MdCheck
                                        : MdClose
                                    }
                                    w={6}
                                    h={6}
                                  />
                                }
                                onClick={() => {
                                  sectionDetailedTrack({
                                    category: GA_CATEGORY_WORKSPACE_PANEL,
                                    action: 'Timeline Attachment',
                                    label: 'Remove File',
                                  })
                                  if (completedList.includes(name)) {
                                  } else removeFile(file)
                                  // completedList.includes(name)
                                  // ? () => null
                                  // : () => removeFile(file)
                                }}
                              />
                            )}
                          </Flex>
                        </ListItem>
                        <Divider border="1px" borderColor="white" mt={2} />
                      </Box>
                    )
                  })}
                </List>
              </VStack>
              <ModalFooter
                w="full"
                py={2}
                alignSelf="flex-end"
                justifySelf="flex-end"
              >
                <Button
                  variant="outline"
                  colorScheme={localStorage.getItem('color')}
                  mr={3}
                  onClick={e => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_WORKSPACE_PANEL,
                      action: 'Timeline Attachment',
                      label: 'Cancel',
                    })
                    onClose(e)
                  }}
                  borderRadius="md"
                  boxShadow="md"
                  size="md"
                >
                  {locale['Cancel']}
                </Button>
                <Button
                  borderRadius="md"
                  boxShadow="md"
                  size="md"
                  disabled={!fileList.length || !!isLoading}
                  variant="solid"
                  colorScheme={localStorage.getItem('color')}
                  bg={localStorage.getItem('color')}
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_WORKSPACE_PANEL,
                      action: 'Timeline Attachment',
                      label: 'Upload',
                    })
                    onUpload()
                  }}
                  isLoading={isLoading}
                  loadingText="Uploading"
                >
                  {locale['Upload']}
                </Button>
              </ModalFooter>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TimelineAttachment

import React, { useState, useEffect } from 'react'

import {
  Modal,
  Text,
  HStack,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useAuthContext } from '../../../../../../context/auth.context'
import { useLanguageContext } from '../../../../../../context/locale.context'
import { useSharedContext } from '../../../../../../context/shared.context'
import {
  useInviteParticipant,
  useAddTimelineUpdate,
} from '../../../../../../hooks/activity.hooks'
import { sharedConst } from '../../../../../../utils/action.constant'
import { sanitizeContent } from '../../../../../../utils/common.util'
import {
  formatDueDateForTimelineCurrDate,
  formatNoteUpdateDate,
} from '../../../../../../utils/date.utils'
import {
  GA_CATEGORY_WORKSPACE_PANEL,
  sectionDetailedTrack,
} from '../../../../../repository/repo.utils'

const ActivityJoinModal = ({ isOpen, onClose, activity }) => {
  const { mutate: inviteMutate } = useInviteParticipant()
  const { mutate: mutateTimelineUpdate } = useAddTimelineUpdate()
  const { state: {
    selectedQueue
  }, dispatch } = useSharedContext()
  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()

  const onComment = ({ comment }) => {
    let payload = {
      content: sanitizeContent(comment),
      subject: 'Note - ' + formatNoteUpdateDate(),
      mail_body: sanitizeContent(comment),
      attachments: [],
      activity_reference: [{ activity_title: '', activity_id: '' }],
      asset_reference: [],
      form_approval_field_reference: [],
      is_reply: 0,
    }
    mutateTimelineUpdate(
      {
        operating_asset_first_name: authData.operating_asset_first_name,
        activity_id: activity.activity_id,
        activity_type_category_id: activity.activity_type_category_id,
        activity_type_id: activity.activity_type_id,
        // data_entity_inline: JSON.stringify(payload),
        activity_timeline_collection: JSON.stringify(payload),
      },
      {
        onSuccess: async data => {
          dispatch({
            type: sharedConst.REFRESH_TIMELINE,
          })
          onClose(true, true)
        },
      }
    )
  }

  const onHandleJoinAsParticipant = isLead => {
    inviteMutate(
      {
        is_lead: isLead,
        activity_id: activity.activity_id,
        activity_type_category_id: 48,
        activity_type_id: activity.activity_type_id,
        contact: {
          asset_type_id: authData.asset_type_id,
          asset_id: authData.asset_id,
          asset_first_name: authData.asset_first_name,
          operating_asset_first_name: authData.operating_asset_first_name,
          workforce_name: authData.authData,
        },
      },
      {
        onSuccess: async data => {
          let content = `${authData.operating_asset_first_name
            } joined at ${formatDueDateForTimelineCurrDate()}`
          if (isLead) {
            if (activity.activity_lead_operating_asset_first_name) {
              content = `Activity lead has been changed from
            "${activity.activity_lead_operating_asset_first_name}"
            to ${authData.operating_asset_first_name
                } at ${formatDueDateForTimelineCurrDate()}`
            } else {
              content = `${authData.operating_asset_first_name
                } join as Lead at ${formatDueDateForTimelineCurrDate()}`
            }
          }
          onComment({ comment: content })
        },
        onError: async data => {
          console.log('Error invite participant', data)
          onClose()
        },
      }
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{locale['Are you sure?']}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            {locale['Join']} &nbsp;
            {activity.activity_title}
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-between" alignItems="center">
            {selectedQueue && selectedQueue.hasOwnProperty('queue_flag_join_lead') && selectedQueue.queue_flag_join_lead === 1 ?
              <>
                  <Button
                    colorScheme={localStorage.getItem('color')}
                    size="sm"
                    borderRadius="0"
                    boxShadow="sm"
                    variant="outline"
                    mr={3}
                    onClick={() => {
                      sectionDetailedTrack({
                        category: GA_CATEGORY_WORKSPACE_PANEL,
                        action: 'Timeline Bottom Panel',
                        label: 'Join As Lead',
                      })
                      onHandleJoinAsParticipant(1)
                    }}
                  >
                    {locale['Join As Lead']}
                  </Button>
              </>
              : selectedQueue && selectedQueue.hasOwnProperty('queue_flag_join_lead') && selectedQueue.queue_flag_join_lead === 2 ? 
              <>
                <Button
                  colorScheme={localStorage.getItem('color')}
                  size="sm"
                  borderRadius="0"
                  boxShadow="sm"
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_WORKSPACE_PANEL,
                      action: 'Timeline Bottom Panel',
                      label: 'Join As Participant',
                    })
                    onHandleJoinAsParticipant(0)
                  }}
                >
                  {locale['Join As Participant']}
                </Button>
              </>
              :
              <>
                <Button
                  colorScheme={localStorage.getItem('color')}
                  size="sm"
                  borderRadius="0"
                  boxShadow="sm"
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_WORKSPACE_PANEL,
                      action: 'Timeline Bottom Panel',
                      label: 'Join As Lead',
                    })
                    onHandleJoinAsParticipant(1)
                  }}
                >
                  {locale['Join As Lead']}
                </Button>
                <Button
                  colorScheme={localStorage.getItem('color')}
                  size="sm"
                  borderRadius="0"
                  boxShadow="sm"
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    sectionDetailedTrack({
                      category: GA_CATEGORY_WORKSPACE_PANEL,
                      action: 'Timeline Bottom Panel',
                      label: 'Join As Participant',
                    })
                    onHandleJoinAsParticipant(0)
                  }}
                >
                  {locale['Join As Participant']}
                </Button>
              </>}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ActivityJoinModal
